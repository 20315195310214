/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { SHIPPING_METHODS } from '@/constants/shippingMethods';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import { type AxiosInstance, type AxiosResponse } from 'axios';
import type {
  ShipmentOptionsData,
  ShipmentOptionsOptions,
  ShipmentOptionsResponse,
} from '../interface';

const URL = `${requestBaseURL}/ecomm/b2c/order/v2/shipment/options`;

const getShipmentOptions = async (
  _options: ShipmentOptionsOptions,
  axiosInstance?: AxiosInstance
) => {
  const response = await getAxios(axiosInstance).get<ShipmentOptionsResponse>(URL);

  return selector(response);
};

export const selector = (response: AxiosResponse<ShipmentOptionsResponse>): ShipmentOptionsData => {
  const shippingIsSddEligible =
    response.data.orderSipmentOptionList?.some((shipmentOption) => {
      const { availableShippingMethods = [] } = shipmentOption;
      return availableShippingMethods.some((shippingMethod) => {
        return shippingMethod.shippingMethodId === SHIPPING_METHODS.SAMEDAY;
      });
    }) ?? false;

  const noAvailableShippingMethod =
    response.data.orderSipmentOptionList?.some(
      (option) => option.selectedShippingMethodId == null
    ) ?? false;

  return {
    ...response.data,
    shippingIsSddEligible,
    noAvailableShippingMethod,
  };
};

const { useData: useShipmentOptionsData, query: shipmentOptionsQuery } = createQuery<
  ShipmentOptionsData,
  ShipmentOptionsOptions
>('shipmentOptions', getShipmentOptions);

const useShipmentOptions = () => {
  return useShipmentOptionsData({
    enabled: false,
    staleTime: Infinity,
  });
};

export { useShipmentOptions, shipmentOptionsQuery };
