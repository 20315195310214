/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import NextImage from '@/components/NextImage';

interface Props {
  freeShippingMargin: string | undefined;
  hideBottomBorder?: boolean;
}

const labelMap = {
  lblFreeDelivery: 'label_free_delivery',
  lblSpend: 'label_spend',
  lblMoreOnShipped: 'label_more_on_shipped',
  lblItemsFor: 'label_items_for',
};

const FreeShippingNote = ({ freeShippingMargin, hideBottomBorder = false }: Props) => {
  const labels = useLabels(labelMap);

  return (
    <div
      className={cx(styles.freeShippingContainer, {
        [styles.freeShippingContainerBorder]: !hideBottomBorder,
      })}
      data-testid="free-shipping-messaging-container"
    >
      <NextImage src="/images/free-sth-messaging.svg" alt="" width={32} height={32} />
      <div>
        <p>
          {`${labels.lblSpend} `}
          <span className={styles.freeDelivery}>${freeShippingMargin}</span>
          {` ${labels.lblMoreOnShipped}`} {labels.lblItemsFor}{' '}
          <span className={styles.freeDelivery}> {labels.lblFreeDelivery} </span>
        </p>
      </div>
    </div>
  );
};

export default FreeShippingNote;
