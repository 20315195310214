/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const SHIPPING_METHODS = {
  NEXTDAY: '206',
  SAMEDAY: '207',
  GROUND: '201',
  FREIGHT: '208',
  EXPRESS: '203',
  USPS_GROUND: '205',
};
