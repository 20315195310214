/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { isAxiosError } from './isAxiosError';

type ErrorWithTitle = {
  message: string;
};

export function toErrorWithTitle(maybeError: unknown): ErrorWithTitle {
  if (isAxiosError(maybeError)) {
    return new Error(maybeError.response?.data.title);
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}
