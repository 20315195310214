/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { DefaultOption, MergedFulfillmentOption } from '../types';

export const mergeDefaultWithState = (
  inputFulfillmentOptions: MergedFulfillmentOption[] | undefined,
  defaultList = DEFAULT_FULFILLMENT_OPTIONS
): MergedFulfillmentOption[] => {
  const defaultListSorted = defaultList.sort((a, b) => b.fulfillmentTypeId - a.fulfillmentTypeId);
  const inputValuesSorted = inputFulfillmentOptions?.sort(
    (a, b) => b.fulfillmentTypeId - a.fulfillmentTypeId
  );

  const mergedArray: MergedFulfillmentOption[] = defaultListSorted.map((element) => {
    const _element = inputValuesSorted?.find((e) => {
      return element.fulfillmentTypeId === e.fulfillmentTypeId;
    });

    return _element ?? element;
  });

  return mergedArray.sort((a, b) => b.fulfillmentTypeId - a.fulfillmentTypeId);
};

export const DEFAULT_FULFILLMENT_OPTIONS: DefaultOption[] = [
  {
    fulfillmentTypeId: 108,
    available: false,
    availableQty: 0,
    availableAtSelectedQuantity: false,
    fulfillmentTypeLabel: 'Free In-Store or Curbside Pick Up',
    groupId: 108,
  },
  {
    fulfillmentTypeId: 107,
    available: false,
    availableQty: 0,
    availableAtSelectedQuantity: false,
    fulfillmentTypeLabel: 'Next Day Delivery',
    groupId: 101,
  },
  {
    fulfillmentTypeId: 106,
    available: false,
    availableQty: 0,
    availableAtSelectedQuantity: false,
    fulfillmentTypeLabel: 'Same Day Delivery',
    groupId: 101,
  },
  {
    fulfillmentTypeId: 101,
    available: false,
    availableQty: 0,
    availableAtSelectedQuantity: false,
    fulfillmentTypeLabel: 'Standard Delivery',
    groupId: 101,
  },
];
