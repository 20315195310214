/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  type OptionalOrderItemFromState,
  mapGrandOrderDiscountListFromState,
} from './mapGrandOrderDiscountListFromState';
import type { MappedOrderLineItem, MappedGrandOrder } from '../types';

export function mapOrderItemsFromState(
  orderItems: OptionalOrderItemFromState[] | undefined
): MappedGrandOrder {
  const grandOrderDiscountList = mapGrandOrderDiscountListFromState(orderItems);
  const grandOrderDeals = grandOrderDiscountList?.filter(
    (discount) => discount.discountTypeId === 2 || discount.discountTypeId === 3
  );
  const grandOrderPromotions = grandOrderDiscountList?.filter(
    (discount) => discount.discountTypeId === 1
  );
  const promotionCountOnOrder = grandOrderPromotions?.length ?? 0;
  const dealCountOnOrder = grandOrderDeals?.length ?? 0;
  const orderLineItems: MappedOrderLineItem[] = [];
  const orders = orderItems?.map((order) => order.orderId);
  orderItems?.forEach((order) => {
    const orderType = order.orderType ?? null;
    order.shipmentInfoList?.filter((shipment) => {
      shipment?.lineItemList?.map((orderLineItem) => {
        const {
          lineItemId,
          productInfo,
          itemQuantity,
          lineItemPriceInfo,
          couponApplied,
          dealApplied,
          discountsExcluded,
        } = orderLineItem;
        const selectedFulfillmentType = orderLineItem.selectedFulfillmentType;
        const { corePrice, listPrice, dealAppliedAmount, unitPrice, discounted } =
          lineItemPriceInfo;
        const { skuId, brand, originalPartType, productId, clearanceItem, partNumber } =
          productInfo;
        const promotionsAppliedToItem = couponApplied
          ? order.couponInfoList.filter((promo) => promo.applied).map((promo) => promo.code)
          : [];
        const dealsAppliedToItem = dealApplied
          ? order.promotionInfoList.filter((deal) => deal.applied).map((deal) => deal.code)
          : [];
        const orderId = order.orderId;
        const mappedItem: MappedOrderLineItem = {
          lineItemId,
          skuId,
          productPartNumber: partNumber,
          brand,
          itemQuantity,
          corePrice,
          listPrice,
          selectedFulfillmentType,
          orderType,
          dealAppliedAmount,
          originalPartType,
          couponApplied,
          promotionsAppliedToItem,
          dealsAppliedToItem,
          dealApplied,
          discountsExcluded,
          orderId,
          productId,
          unitPrice,
          discounted,
          clearanceItem,
        };
        orderLineItems.push(mappedItem);
      });
    });
  });
  orderLineItems.sort((a, b) =>
    a.lineItemId > b.lineItemId ? 1 : b.lineItemId > a.lineItemId ? -1 : 0
  );
  const combinedData: MappedGrandOrder = {
    promotionCountOnOrder: promotionCountOnOrder,
    dealCountOnOrder: dealCountOnOrder,
    grandOrderPromotions,
    grandOrderDeals,
    orders,
    orderLineItems,
    grandOrderDiscountList,
  };
  return combinedData;
}
