/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { toErrorWithTitle } from './toErrorWithTitle';

export function getErrorTitle(error: unknown): string | undefined {
  if (error) {
    return toErrorWithTitle(error).message || undefined;
  } else {
    return undefined;
  }
}
