/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from './styles.module.scss';
import cx from 'classnames';

type Props = {
  firstText?: React.ReactNode;
  secondText?: React.ReactNode;
  underline?: boolean;
  swapStyle?: boolean;
  headerType?: React.ElementType;
  overRuleH1?: React.ElementType;
  variant?: string;
  center?: boolean;
  stylesClass?: any | string;
  firstTextClass?: any | string;
  secondTextClass?: any | string;
  id?: string;
  inline?: boolean;
  dataTestIdContainer?: string;
  dataTestIdFirstText?: string;
  dataTestIdSecondText?: string;
  isSameTitleTag?: boolean;
  isNextLine?: boolean;
  toOverrideHeaderTag?: boolean;
  usesWrapperTags?: boolean;
};

const Title = ({
  firstText,
  secondText = '',
  underline = false,
  swapStyle = false,
  headerType,
  center = false,
  stylesClass,
  firstTextClass,
  secondTextClass,
  overRuleH1,
  id = undefined,
  variant = 'lg',
  inline,
  dataTestIdContainer = '',
  dataTestIdFirstText = '',
  dataTestIdSecondText = '',
  isSameTitleTag = false,
  isNextLine = false,
  toOverrideHeaderTag = false,
  usesWrapperTags = false,
}: Props) => {
  let primaryClass;
  let secondaryClass;

  switch (variant) {
    case 'xl':
      // $font-size-xl: 2.074rem
      primaryClass = azCommonStyles['az-display-1-heavy'];
      secondaryClass = azCommonStyles['az-display-1-regular'];
      break;
    case 'lg':
      // $font-size-lg: 1.728rem
      primaryClass = azCommonStyles['az-title-1-heavy'];
      secondaryClass = azCommonStyles['az-title-1-regular'];
      break;
    case 'md':
      // $font-size-md: 1.44rem
      primaryClass = azCommonStyles['az-title-2-heavy'];
      secondaryClass = azCommonStyles['az-title-2-regular'];
      break;
    case 'sm':
      // $font-size-sm: 1.2rem
      primaryClass = azCommonStyles['az-title-3-heavy'];
      secondaryClass = azCommonStyles['az-title-3-regular'];
      break;
    case 'xs':
      // $font-size-base: 1rem
      primaryClass = azCommonStyles['az-title-4-heavy'];
      secondaryClass = azCommonStyles['az-title-4-regular'];
      break;
    case 'xxs':
      primaryClass = azCommonStyles['az-title-5-heavy'];
      secondaryClass = azCommonStyles['az-title-5-regular'];
      break;
    case 'azTitleMedium':
      primaryClass = azCommonStyles['az-title-5-medium'];
      secondaryClass = secondTextClass ? secondTextClass : '';
      break;
    case 'azTableTitle':
      primaryClass = azCommonStyles['az-body-1-heavy'];
      secondaryClass = secondTextClass ? secondTextClass : '';
      break;
    default:
      // FONT-SIZE: 20px
      primaryClass = azCommonStyles['az-title-5-heavy'];
      secondaryClass = azCommonStyles['az-title-5-regular'];
      break;
  }

  if (swapStyle) {
    const temp = primaryClass;
    primaryClass = secondaryClass;
    secondaryClass = temp;
  }

  const CustomHeader = headerType || 'div';
  const H1OrH2 = overRuleH1 || 'h2';
  const H2OrH3 = !toOverrideHeaderTag
    ? (overRuleH1 === 'h1' && 'h2') ||
      (overRuleH1 === 'h2' && 'h3') ||
      (overRuleH1 === 'h3' && 'h4') ||
      (overRuleH1 === 'h4' && 'h5') ||
      (overRuleH1 === 'h5' && 'h6') ||
      'h3'
    : 'span';
  const SpanOrH2 = usesWrapperTags ? 'h2' : 'span';
  return (
    <CustomHeader
      className={cx(
        underline ? styles.underline : '',
        center ? styles.center : '',
        String(stylesClass || ''),
        primaryClass,
        inline ? styles.inlineheader : ''
      )}
      data-testid={dataTestIdContainer || azCommonStyles['az-title-container']}
      id={id}
    >
      {firstText && (
        <H1OrH2
          className={cx(primaryClass, String(firstTextClass || ''))}
          data-testid={dataTestIdFirstText || 'at_title_first_text'}
        >
          {usesWrapperTags ? <p className={styles.firstText}>{firstText}</p> : firstText}
          {inline ? '' : ' '}
          {secondText && isSameTitleTag && (
            <>
              {isNextLine && <br />}
              <SpanOrH2
                className={cx(
                  secondaryClass,
                  String(secondTextClass || ''),
                  inline ? styles.inlineH2orH3 : ''
                )}
                data-testid={dataTestIdSecondText || 'at_title_second_text_second_text'}
              >
                {secondText}
              </SpanOrH2>
            </>
          )}
        </H1OrH2>
      )}
      {secondText && !isSameTitleTag && (
        <H2OrH3
          className={cx(
            secondaryClass,
            String(secondTextClass || ''),
            inline ? styles.inlineH2orH3 : ''
          )}
          data-testid={dataTestIdSecondText || 'at_title_second_text_second_text'}
        >
          {secondText}
        </H2OrH3>
      )}
    </CustomHeader>
  );
};

export default Title;
