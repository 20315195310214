/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import dayjs from 'dayjs';
import { useLabel } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import CountDownV2 from './CountDownV2';
import EstDeliveryDate from './EstDeliveryDate';
import { useFeatureFlag } from '@/features/globalConfig';
type Props = {
  estDeliveryDateString?: string;
  cutOffDateString: string;
  orderByLabel: string;
  arrivesByLabel: string;
  displayAmPmFormat: boolean;
  shippingArriveBy?: string;
  // hrs mins if false or null
  styleOverride?: any;
  isCheckoutPage?: boolean;
};

export function OrderWithinHrsMinArrivesMessage({
  estDeliveryDateString,
  cutOffDateString,
  orderByLabel,
  arrivesByLabel,
  displayAmPmFormat,
  styleOverride,
  shippingArriveBy,
  isCheckoutPage,
}: Props) {
  const labelOrderBy = useLabel(orderByLabel);
  const labelArrives = useLabel(arrivesByLabel);
  const cutOffDateTime = dayjs(cutOffDateString);
  const labelShippingArriveBy = useLabel(shippingArriveBy!);
  const arriveByLabelV3 = useFeatureFlag('CHECKOUT_V3_ENABLED_US') === 'true';

  if (!cutOffDateTime.isValid()) {
    return null;
  }

  return (
    <div
      data-testid="orderWithin"
      className={arriveByLabelV3 ? styles.shippingOptionDetailV3 : styles.shippingOptionDetail}
      style={styleOverride ? styleOverride : null}
    >
      <span data-testid="orderby">{labelOrderBy} </span>
      <strong>
        <CountDownV2
          toDate={cutOffDateTime.toDate()}
          amPm={displayAmPmFormat}
          isCheckoutPage={isCheckoutPage}
        />
      </strong>{' '}
      {!!estDeliveryDateString && (
        <>
          <span data-testid="estimated-delivery-date-label">
            {arriveByLabelV3 ? labelShippingArriveBy : labelArrives}{' '}
          </span>
          <span
            data-testid="estimated-delivery-date"
            className={arriveByLabelV3 ? styles.deliveryMessageV3 : styles.deliveryMessage}
          >
            <EstDeliveryDate estimatedDeliveryDate={estDeliveryDateString} />
          </span>
        </>
      )}
    </div>
  );
}
