/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type {
  FulfillmentOption,
  FulfillmentOptionSkuDetailsFromState,
} from '@/types/reduxStore/skuDetails';
import type { FulfillmentOptionUpdated, MergedFulfillmentOption } from '../types';

export function getMaxAvailQtyFromOptions(
  fulfillmentOptions:
    | FulfillmentOptionUpdated[]
    | FulfillmentOption[]
    | FulfillmentOptionSkuDetailsFromState[]
    | MergedFulfillmentOption[]
): number {
  const maxAvailableQty = Math.max(...fulfillmentOptions.map((o) => o.availableQty));

  return maxAvailableQty;
}
