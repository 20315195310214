/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AxiosInstance } from 'axios';
import type { SkuDealsResponse } from '../interface';
import type { SkuDealsFromAPI } from '@/types/availability';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { requestBaseURL } from '@/config/serviceAPI';
import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import { type QueryClient } from '@tanstack/react-query';

type ProductDealsQueryOptions = {
  skuIds: Array<string | null> | null;
  vehicleId: string | undefined;
  storeNumber: string | undefined;
};

const URL = `${requestBaseURL}/ecomm/b2c/browse/v3/deal/details`;

const getProductSkuDeals = async (
  options: ProductDealsQueryOptions,
  axiosInstance?: AxiosInstance
) => {
  const { skuIds } = options;

  if (!skuIds) {
    throw new Error('skuIds is required');
  }

  const response = await getAxios(axiosInstance).get<SkuDealsResponse>(
    `${URL}/${skuIds.join(',')}`
  );
  return response.data;
};

const { useData: useProdSkuDeals, prefetch: prefetchProdSkuDealsData } = createQuery<
  SkuDealsFromAPI[],
  ProductDealsQueryOptions
>('productSkuDeals', getProductSkuDeals);

export const useProductSkuDeals = ({
  skuIds,
  enabled = Boolean(skuIds?.length),
}: {
  skuIds: string[] | null | undefined;
  enabled?: boolean;
}) => {
  const headerResult = useHeaderData();
  const locale = useLocale();
  const storeNumber = headerResult.data?.storeNumber;
  const vehicleId = getPreferredVehicle(headerResult.data)?.catalogVehicleId;
  const isEnabled = headerResult.isSuccess && enabled && !!skuIds;

  return useProdSkuDeals({
    enabled: locale === countryCodes.ptBr ? false : isEnabled,
    skuIds: skuIds?.length ? skuIds : null,
    storeNumber: storeNumber ?? '',
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    vehicleId: vehicleId || '0',
    cacheTime: 0,
  });
};

export const prefetchProductSkuDeals = async (
  options: ProductDealsQueryOptions,
  queryClient: QueryClient,
  axiosInstance: AxiosInstance
) => {
  return prefetchProdSkuDealsData(queryClient, options, axiosInstance);
};
