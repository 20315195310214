/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { FormControlLabel } from '../../../FormControlLabel/FormControlLabel';
import { SwitchBase } from '../../../Checkbox/SwitchBase';
import RadioButtonUncheckedIcon from '../../../../../public/images/radio-button-unchecked.svg';
import RadioButtonCheckedIcon from '../../../../../public/images/radio-button-checked.svg';
import iconStyles from '../../../../theme/iconStyles.module.scss';
import styles from './styles.module.scss';

type RadioProps = {
  checked: boolean;
  autoFocus?: boolean;
  name?: string;
  value: string | number | React.ReactElement;
  label?: string | React.ReactNode;
  disabled?: boolean;
  id?: string;
  dataId?: string;
  inputDataTestId?: string;
  onChange?: ChangeEventHandler<HTMLElement>;
  onClick?: MouseEventHandler<HTMLLabelElement>;
  ariaLabel?: string;
  hideRadioButton?: boolean;
};

type Props = {
  subLabel?: any | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
} & RadioProps;

const RadioButton = (props: RadioProps) => {
  const {
    value,
    name,
    disabled,
    id,
    dataId,
    onChange,
    inputDataTestId,
    checked,
    ariaLabel,
    hideRadioButton,
    autoFocus,
    ...remainingProps
  } = props;
  const rootDisabled = disabled ? styles.radioRootDisabled : styles.radioRoot;
  const checkedDisabled = disabled ? styles.radioCheckedDisabled : styles.radioChecked;

  return (
    <>
      {hideRadioButton ? (
        <div data-testid="radio-hidden-container" className={styles.radioHiddenContainer} />
      ) : (
        <div data-testid="radio-button-container" className={styles.radioContainer}>
          <SwitchBase
            checked={checked}
            onChange={onChange}
            // @ts-expect-error fix type
            value={value}
            id={id}
            data-testid={dataId}
            name={name}
            disabled={disabled}
            aria-label={ariaLabel}
            type="radio"
            autoFocus={autoFocus}
            icon={
              <>
                {disabled ? <span className={styles.radioDisabled} /> : ''}
                <RadioButtonUncheckedIcon
                  className={cx(
                    iconStyles.defaultIconStyle,
                    iconStyles.fontSizeSmall,
                    styles.iconSize
                  )}
                />
                <span className="sr-only">{ariaLabel}</span>
              </>
            }
            inputProps={{
              'data-testid': inputDataTestId,
            }}
            checkedIcon={
              <RadioButtonCheckedIcon
                className={cx(
                  iconStyles.defaultIconStyle,
                  iconStyles.fontSizeSmall,
                  styles.iconSize
                )}
              />
            }
            className={cx(rootDisabled, checkedDisabled)}
            {...remainingProps}
          />
        </div>
      )}
    </>
  );
};

const CustomRadioInput = (props: Props) => {
  const {
    value,
    label = '',
    disabled = false,
    labelPlacement = 'end',
    subLabel = '',
    id,
    inputDataTestId,
    dataId,
    onChange,
    name,
    ariaLabel,
    checked,
    onClick,
    hideRadioButton,
    autoFocus,
  } = props;
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      // @ts-expect-error fix type
      onChange={onChange}
      aria-label={ariaLabel}
      control={
        <RadioButton
          id={id}
          inputDataTestId={inputDataTestId}
          dataId={dataId}
          value={value}
          label={label}
          disabled={disabled}
          checked={checked}
          onClick={onClick}
          hideRadioButton={hideRadioButton}
          autoFocus={autoFocus}
        />
      }
      label={
        <>
          <div
            className={
              disabled && name && !name.includes('fullfilment') ? styles.radioLabelDisabled : ''
            }
          >
            {label}
          </div>
          {subLabel}
        </>
      }
      labelPlacement={labelPlacement}
      className={cx(styles.radioFormControl, styles.radioLabel)}
      checked={checked}
      onClick={onClick}
    />
  );
};

export default CustomRadioInput;
