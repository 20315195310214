/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { mergeDefaultWithState } from './mergeDefaultWithState';
import type { MergedFulfillmentOption } from '../types';

export function createItemFulfillmentAvailabilityString(
  inputFulfillmentOptions: MergedFulfillmentOption[]
): string {
  const allOptions = mergeDefaultWithState(inputFulfillmentOptions);
  return allOptions
    .map((option) => `${option.fulfillmentTypeId}-${option.available ? 'true' : 'false'}`)
    .join(':');
}
