/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AxiosInstance } from 'axios';
import {
  mapCommerceIdToFulfillmentOptions,
  checkContainsSddEligibleItem,
  mapLineItemFulfillmentOptionGroupsFromAPI,
} from '../adaptors';
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import type { AvailabilityInfoResponse, AvailabilityInfoData } from '../interface';
import { createQuery } from '@/utils/createReactQuery';

const URL = `${requestBaseURL}/ecomm/b2c/order/v4/availability`;

export const getAvailabilityInfoKey = () => ['availabilityInfo'] as const;

const getAvailabilityInfoData = async (axiosInstance?: AxiosInstance) => {
  const response = await getAxios(axiosInstance).get<AvailabilityInfoResponse>(URL);

  return selector(response.data);
};

const { useData: useAvailabilityInfo } = createQuery(
  'availabilityInfo',
  async (_options: object, axiosInstance?: AxiosInstance) => getAvailabilityInfoData(axiosInstance)
);

const selector = (data: AvailabilityInfoResponse): AvailabilityInfoData => {
  const { lineItemFulfillmentOptions, oneFulfillment } = data;
  const availabilityData = mapCommerceIdToFulfillmentOptions(lineItemFulfillmentOptions);
  const containsSddEligible = checkContainsSddEligibleItem(lineItemFulfillmentOptions);
  const itemAvailability = mapLineItemFulfillmentOptionGroupsFromAPI(lineItemFulfillmentOptions);

  const parsedAvailabilityInfo = {
    availabilityData,
    containsSddEligible,
    itemAvailability,
    oneFulfillment,
  };
  return parsedAvailabilityInfo;
};

export const useAvailabilityInfoData = () => {
  return useAvailabilityInfo();
};
