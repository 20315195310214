/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import dayjs from 'dayjs';
import { dayjsFormats } from '@/constants/date';

export function getFormattedAmPmShort(date: string): string {
  const estDeliveryDateTime = dayjs(date);

  if (estDeliveryDateTime.isValid()) {
    const formattedDate = estDeliveryDateTime.format(dayjsFormats.timeOnly);
    return formattedDate.toLowerCase();
  }

  return date.toString();
}
