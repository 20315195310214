/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { GrandOrderDiscountList, MappedDiscount } from '../types';
import type {
  CouponInfoFromState,
  LineItemFromState,
  OrderItemFromState,
  PromotionInfoFromState,
  ShipmentInfoFromState,
} from '@/types/reduxStore/orders';

type OverrideProperties<T1, T2> = Omit<T1, keyof T2> & T2;

export type OptionalOrderItemFromState = OverrideProperties<
  OrderItemFromState,
  {
    shipmentInfoList:
      | Array<
          | OverrideProperties<
              ShipmentInfoFromState,
              {
                lineItemList: LineItemFromState[] | undefined;
              }
            >
          | undefined
        >
      | undefined;
  }
>;

export function mapGrandOrderDiscountListFromState(
  orderItems: OptionalOrderItemFromState[] | undefined
): GrandOrderDiscountList | undefined {
  const containsPromosAndCoupons = orderItems?.some(
    (order) => order.promotionInfoList && order.couponInfoList
  );

  if (containsPromosAndCoupons) {
    return orderItems
      ?.map<Array<CouponInfoFromState | PromotionInfoFromState>>(
        ({ promotionInfoList, couponInfoList }) => [
          ...(couponInfoList || []),
          ...(promotionInfoList || []),
        ]
      )
      .reduce((accumulator, currentArray) => [...accumulator, ...currentArray], [])
      .map((discount) => {
        const _discount = { ...discount };

        if ('couponPromotion' in discount && discount.couponPromotion) {
          _discount.discountTypeId = 1;
        } else if ('inStoreDeal' in discount && discount.inStoreDeal) {
          _discount.discountTypeId = 2;
        } else if ('onlineOnlyDeal' in discount && discount.onlineOnlyDeal) {
          _discount.discountTypeId = 3;
        }

        return _discount;
      })
      .reduce((accumulator: Array<CouponInfoFromState | PromotionInfoFromState>, curr) => {
        const promoIndex = accumulator.findIndex((promotion) => promotion.code === curr.code);

        if (promoIndex >= 0) {
          const currentPromoInstance = { ...accumulator[promoIndex] };
          currentPromoInstance.applied = currentPromoInstance.applied || curr.applied;
          accumulator[promoIndex] = currentPromoInstance;
          return accumulator;
        } else {
          return [...accumulator, curr];
        }
      }, [])
      .map((discount) => {
        return {
          id: discount.code,
          applied: discount.applied,
          amount: discount.amount,
          discountTypeId: discount.discountTypeId,
          description: 'description' in discount ? discount.description : undefined,
          name: 'name' in discount ? discount.name : undefined,
          couponPromotion: 'couponPromotion' in discount ? discount.couponPromotion : undefined,
          inStoreDeal: 'inStoreDeal' in discount ? discount.inStoreDeal : undefined,
          discountMessage: 'discountMessage' in discount ? discount.discountMessage : undefined,
        } as MappedDiscount;
      });
  } else {
    return [];
  }
}
