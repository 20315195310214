/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { getFulfillmentOptionsByGroup } from '@/utils/itemHelpers/fulfillmentHelpers/getFulfillmentOptionsByGroup';
import type { LineItemFulfillmentOptionsFromAPI } from './interface';

export const mapCommerceIdToFulfillmentOptions = (
  lineItemFulfillmentOptions: LineItemFulfillmentOptionsFromAPI[]
) => {
  const availabilityInfo: Record<string, FulfillmentGroup[]> = {};
  lineItemFulfillmentOptions.map((option) => {
    const fullfillmentGroup = getFulfillmentOptionsByGroup(option.fulfillmentOptions);
    availabilityInfo[option.commerceItemId] = fullfillmentGroup;
  });
  return availabilityInfo;
};

export function checkContainsSddEligibleItem(
  lineItemFulfillmentOptions: LineItemFulfillmentOptionsFromAPI[]
): boolean {
  const foundSddItem = lineItemFulfillmentOptions.some((option) => {
    const { fulfillmentOptions } = option;
    const sddEligible = fulfillmentOptions.some((fulfillmentOption) => {
      return (
        fulfillmentOption.fulfillmentTypeId === FULFILLMENT_METHODS.SAMEDAY &&
        fulfillmentOption.available
      );
    });
    return sddEligible;
  });
  return foundSddItem;
}

export function mapLineItemFulfillmentOptionGroupsFromAPI(
  lineItemFulfillmentOptions: LineItemFulfillmentOptionsFromAPI[]
) {
  const mappedItemAvailByGroupArr: Array<{
    fulfillmentGroups: FulfillmentGroup[];
    lineItemId: string;
    skuId: string;
    dealConfigurableLabel: string | null | undefined;
  }> = [];
  const mapThisArr = lineItemFulfillmentOptions && Array.from(lineItemFulfillmentOptions);

  if (mapThisArr && mapThisArr.length > 0) {
    mapThisArr.map((item) => {
      const { commerceItemId: lineItemId, skuId, fulfillmentOptions, dealConfigurableLabel } = item;
      const fulfillmentGroups = getFulfillmentOptionsByGroup(fulfillmentOptions);
      const mappedItem = {
        lineItemId,
        skuId,
        fulfillmentGroups,
        dealConfigurableLabel,
      };
      mappedItemAvailByGroupArr.push(mappedItem);
    });
    return mappedItemAvailByGroupArr;
  }
}
